$status-collapsed-height: 10em;
$notification-collapsed-height: 6em;

.status__wrapper {
  &.collapsed {
    .status {
      &__content {
        height: unset;
        max-height: $status-collapsed-height;
        padding-top: unset;
        mask-image: linear-gradient(#000 80%, transparent);

        &::after {
          top: unset;
          height: 40%;
        }

        // "See more" button
        &__spoiler-link {
          background: $action-button-color;

          &:hover,
          &:focus {
            background: lighten($action-button-color, 7%);
            text-decoration: none;
          }
        }
      }

      &--in-thread > button {
        margin-inline-start: 46px + 10px;
      }
    }
  }
}

// set a smaller height for previews of longposts in notifications
// The component structure is different depending on whether
// grouped notifications is enabled or not, and whether
// something is an interaction or not - there isn't a
// class given for "notifications" so we have to improvise
.notification-ungrouped .status__wrapper.collapsed .status__content,
.notification__filter-bar
  + .scrollable
  .status__wrapper.collapsed
  .status__content {
  max-height: $notification-collapsed-height;
}
