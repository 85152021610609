pre {
  background: darken($ui-base-color, 8%);
  border-radius: 3px;
  padding: 0.75em;
  counter-reset: line;
  overflow-x: scroll;
}

pre code {
  counter-increment: line;
  padding: 0;
  white-space: pre;
}

pre code::before {
  font-size: 0.8em;
  color: darken($primary-text-color, 33%);
  content: counter(line);
  display: inline-block;
  border-right: 1px solid darken($primary-text-color, 33%);
  margin: -0.05em 0.7em -0.05em 0;
  padding: 0.05em 0.5em 0.05em 0;
  text-align: right;
  width: 1.2em;
  white-space: nowrap;
  direction: rtl;
}
